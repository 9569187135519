/** @format */

import React from "react";
import { Link } from "react-router-dom";
// import logo2 from "../../../assetsgeneral/images/head-logo.png"
// import routelogo from "../../../assetsgeneral/images/gpim.png"
// import dispatchlogo from "../../../assetsgeneral/images/gpimg2.png"
// import schedulelogo from "../../../assetsgeneral/images/gpimg3.png"

const HomeMainComponent = () => {
  return (
    <>
      {/* <!-- slider section start --> */}
      <div className="slider-section">
        <div className="container">
          <div className="row ">
            <div className="col-xl-4 col-lg-6 col-md-7 col-sm-12">
              <div className="sliderbg">
                <h1>Non-emergency Medical Transportation</h1>
                <p>
                  Connect Care Transport ensures safe, Wheelchair-Accessible
                  rides across Chicagoland.
                </p>
                <div
                  className="d-flex flex-column flex-md-row gap-3"
                  style={{ gap: "0.8rem" }}
                >
                  <div className="w-100">
                    <Link to="/booking" className="btn btn-secondary w-100">
                      <i className="fab fa-accessible-icon"></i>{" "}
                      {/* <i className="fa-brands fa-accessible-icon me-1"></i>{" "} */}
                      Reservation
                    </Link>
                  </div>
                  <div className="w-100">
                    <button
                      className="btn btn-secondary w-100"
                      // style={{ fontSize: "inherit" }}
                    >
                      <i
                        // className="fas fa-phone border rounded-circle p-1 me-1"
                        className="fa fa-phone border rounded-circle p-1 me-1"
                        style={{ fontSize: "12px", marginRight: "2px" }}
                      ></i>
                      (773) 340-4373
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default HomeMainComponent;
