/** @format */

import axios from "axios";
import React, { useEffect, useState } from "react";
// import logo2 from "../../assetsgeneral/images/head-logo.png"
// import logo2 from "../../assetsgeneral/images/CONNECT CARE TRANS-04.png"
// import logo2 from "../../assetsgeneral/images/CONNECT_CARE_TRANS-04small.jpg"
import logo2 from "../../assets/images/footer-logo.png";
import foterimg from "../../assets/images/foterimg.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import { ENVIRONMENT } from "../../constants";

const Footer = () => {
  const [year, setYear] = useState();
  const getYear = async () => {
    // const { data } = await axios.get(
    //   `https://worldtimeapi.org/api/timezone/Asia/Karachi`
    // );
    // setYear(data.utc_datetime.substring(0, 4));
    const today = new Date();
    setYear(today.getFullYear());
  };
  useEffect(() => {
    getYear();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <div className="footer-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3 mb-sm-3">
              <div className="footer-logo mb-3">
                <Link to="/">
                  <img src={logo2} alt="" />
                </Link>
              </div>
              <div className="widget_social">
                <ul>
                  <li>
                    {/* <Link to="https://www.facebook.com/connectcaretrans/"> */}
                    <a
                      href="https://www.facebook.com/connectcaretrans/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className=" fa fa-facebook-square mx-2 ico-col"></i>
                    </a>
                    {/* </Link> */}
                  </li>
                  <li>
                    {/* <Link to="https://www.linkedin.com/company/connect-care-trans/"> */}
                    <a
                      href="https://www.linkedin.com/company/connect-care-trans/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-brands fa fa-linkedin-square"></i>
                      {/* </Link> */}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3 mb-sm-3">
              <div className="widget">
                <h2>Quick Links</h2>
                <div className="widget_content">
                  <ul>
                    <li>
                      <i className="fa fa-chevron-right" id="foot-icon"></i>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right" id="foot-icon"></i>
                      <Link to="/about-us">About Us</Link>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right" id="foot-icon"></i>
                      <Link to="/services">Services</Link>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right" id="foot-icon"></i>
                      <Link to="/booking">Booking</Link>
                    </li>
                    <li>
                      <i className="fa fa-chevron-right" id="foot-icon"></i>
                      <Link to="/contact-us">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mb-3 mb-sm-3">
              <div className="widget">
                <h2>Contact Info</h2>
                <div className="widget_content">
                  <ul>
                    <li>
                      <i className="fa fa-phone" id="foot-icon"></i>
                      <Link to="#">(773) 340-4373</Link>
                    </li>
                    <li>
                      <i className="fa fa-envelope" id="foot-icon"></i>
                      <Link to="#">Contacts@connectcaretrans.com</Link>
                    </li>
                    <li>
                      <i className="fa fa-globe" id="foot-icon"></i>
                      <Link to="#">www.connectcaretrans.com</Link>
                    </li>
                    <li>
                      <i className="fa fa-map-marker" id="foot-icon"></i>
                      <Link to="">
                        5858 N Northwest Hwy, Chicago,
                        <br />
                        <i
                          className="fa fa-map-marker"
                          id="foot-icon"
                          style={{ visibility: "hidden", paddingRight: "10px" }}
                        ></i>
                        IL 60631
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.bbb.org/us/il/chicago/profile/medical-transportation/connect-care-trans-llc-0654-1000044520">
                        <img src={foterimg} alt="" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bot">
        <div className="container">
          <div className="d-flex align-content-center justify-content-between flex-column flex-sm-row">
            <div className="footerb-text">
              <p>
                Copyright Connect Care Trans {year}. All Rights Reserved.
                Powered By{" "}
                <Link
                  to="https://iconictek.com/"
                  className="text-decoration-none"
                >
                  <strong>Iconic Tek</strong>
                </Link>
              </p>
            </div>
            <div className="footerb-text">
              <p>
                <Link
                  to="/privacy-policy"
                  className="text-decoration-none"
                  // onClick={() => navigate("/privacy-policy")}
                  style={{ cursor: "pointer" }}
                >
                  <strong>Privacy Policy</strong>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
